import React from "react";

function WeOffer({ weOfferContent }) {
  return (
    <section className="m-weOffer">
      <div className="_wr">
        <div className="m-weOffer__top">
          <h2>{weOfferContent.title}</h2>
          <span className="a-line -red"></span>
        </div>
        {weOfferContent.thirdText || weOfferContent.thirdText ? (
          <div className="_w">
            <div className="_m6">
              <div>
                <p className="m-weOffer__text -mb">
                  {weOfferContent.firstText}
                </p>
                <p className="m-weOffer__text">{weOfferContent.secondText}</p>
              </div>
            </div>
            <div className="_m6 -mt">
              <div>
                {weOfferContent.thirdText && (
                  <p className="m-weOffer__text -mb">
                    {weOfferContent.thirdText}
                  </p>
                )}
                {weOfferContent.fourthText && (
                  <p className="m-weOffer__text">{weOfferContent.fourthText}</p>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="_w">
            <p className="m-weOffer__text _6 -mb">{weOfferContent.firstText}</p>
            <p className="m-weOffer__text _6">{weOfferContent.secondText}</p>
          </div>
        )}
      </div>
    </section>
  );
}

export default WeOffer;
