import * as React from "react";
import Layout from "../../components/layout/layout";
import Seo from "../../components/seo/seo";
import Text from "../../components/global/text";
import Projects from "../../components/global/projects";
import Develop from "../../components/global/develop";
import Promise from "../../components/global/promise";
import HeaderGlobal from "../../components/headers/header-global";
import { graphql } from "gatsby";
import PromiseOne from "../../assets/images/global/vision.svg";
import PromiseTwo from "../../assets/images/global/fast-delivery.svg";
import PromiseThree from "../../assets/images/global/future-app.svg";
import WeOffer from "../../components/app-development/we-offer";
import Offer from "../../components/global/offer";
import DesktopImg from "../../assets/images/global/desktop.svg";
import MobileImg from "../../assets/images/global/mobile.svg";
import WebImg from "../../assets/images/global/web.svg";
import BlockchainImg from "../../assets/images/global/blockchain.svg";
import WebsiteImg from "../../assets/images/global/website.svg";
import BigDataImg from "../../assets/images/global/big-data.svg";
import Process from "../../components/global/process";
import HeaderBcg from "../../assets/images/headers/app-dev.png";
import { useTranslation } from "gatsby-plugin-react-i18next";
// Text Component

const ApplicationDevelopment = () => {
  const { t } = useTranslation();

  // Header Content
  const hTitle = `${t("services.appDev.headline")}`;
  const hSubtitle = `${t("services.appDev.shortText")}`;
  const hTextOne = `${t("services.appDev.firstText")}`;
  // const hTextTwo = `${t("services.appDev.secondText")}`;

  const textContent = {
    title: `${t("services.appDev.ourApproach.headline")}`,
    text: `${t("services.appDev.ourApproach.text")}`,
  };

  const promiseHeadline = `${t("services.appDev.promise.headline")}`;

  // Promise Component
  const promiseContent = [
    {
      title: `${t("services.appDev.promise.first.title")}`,
      textOne: `${t("services.appDev.promise.first.firstText")}`,
      textTwo: `${t("services.appDev.promise.first.secondText")}`,
      icon: <PromiseOne />,
    },
    {
      title: `${t("services.appDev.promise.second.title")}`,
      textOne: `${t("services.appDev.promise.second.firstText")}`,
      textTwo: `${t("services.appDev.promise.second.secondText")}`,
      icon: <PromiseTwo />,
    },
    {
      title: `${t("services.appDev.promise.third.title")}`,
      textOne: `${t("services.appDev.promise.third.firstText")}`,
      textTwo: `${t("services.appDev.promise.third.secondText")}`,
      icon: <PromiseThree />,
    },
  ];

  const weOfferContent = {
    title: `${t("services.appDev.offer.headline")}`,
    firstText: `${t("services.appDev.offer.firstText")}`,
    secondText: `${t("services.appDev.offer.secondText")}`,
    thirdText: `${t("services.appDev.offer.thirdText")}`,
    fourthText: `${t("services.appDev.offer.fourthText")}`,
  };

  // Offer Component
  const offerContent = [
    {
      icon: <DesktopImg />,
      title: `${t("services.appDev.offerCards.first.title")}`,
      text: `${t("services.appDev.offerCards.first.text")}`,
    },
    {
      icon: <WebImg />,
      title: `${t("services.appDev.offerCards.second.title")}`,
      text: `${t("services.appDev.offerCards.second.text")}`,
    },
    {
      icon: <MobileImg />,
      title: `${t("services.appDev.offerCards.third.title")}`,
      text: `${t("services.appDev.offerCards.third.text")}`,
    },
    {
      icon: <BlockchainImg />,
      title: `${t("services.appDev.offerCards.fourth.title")}`,
      text: `${t("services.appDev.offerCards.fourth.text")}`,
      // btnHref: "/it-services/blockchain-development",
    },
    {
      icon: <WebsiteImg />,
      title: `${t("services.appDev.offerCards.fifth.title")}`,
      text: `${t("services.appDev.offerCards.fifth.text")}`,
    },
    {
      icon: <BigDataImg />,
      title: `${t("services.appDev.offerCards.sixth.title")}`,
      text: `${t("services.appDev.offerCards.sixth.text")}`,
    },
  ];

  // Process Component
  const processShortText = `${t("services.appDev.process.shortText")}`;

  const processContent = [
    {
      title: `${t("services.appDev.process.first.title")}`,
      text: `${t("services.appDev.process.first.text")}`,
    },
    {
      title: `${t("services.appDev.process.second.title")}`,
      text: `${t("services.appDev.process.second.text")}`,
    },
    {
      title: `${t("services.appDev.process.third.title")}`,
      text: `${t("services.appDev.process.third.text")}`,
    },
    {
      title: `${t("services.appDev.process.fourth.title")}`,
      text: `${t("services.appDev.process.fourth.text")}`,
    },
  ];

  return (
    <Layout>
      <Seo title={t("navigation.services.appDev")} />
      <HeaderGlobal
        hTitle={hTitle}
        hSubtitle={hSubtitle}
        hTextOne={hTextOne}
        image={HeaderBcg}
      />
      <Promise
        promiseContent={promiseContent}
        promiseHeadline={promiseHeadline}
        differentStyle="-appDev"
      />
      <WeOffer weOfferContent={weOfferContent} />
      <Offer
        offerContent={offerContent}
        cssModifer="-app-dev"
        styleClass="-readMore"
      />
      <Text
        textContent={textContent}
        backgroundColor="-approach"
        cssModifer="-homeText"
      />
      <Process
        processContent={processContent}
        processShortText={processShortText}
      />

      <Projects cssModifer="-projectsHome" />
      <Develop />
    </Layout>
  );
};

export default ApplicationDevelopment;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
